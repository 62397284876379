import React, { useState, useRef, useEffect } from 'react'
import './LoginNew.css'
import dmlogo from '../../img/dster-webp/dwhite.webp'
import regN from '../../img/dster-webp/regisN.webp'
import { Button, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Axios from '../../Axios'
import { ToastContainer, toast } from 'react-toastify'
import {
    GoogleAuthProvider,
    signInWithPopup,
    onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../../fireBase/firebase";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function LoginNew() {

    const theme = useTheme();
    const mdScreen = useMediaQuery(theme.breakpoints.up('md'));
    const lgScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const smScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const [select, setSelect] = useState(null)
    const [userTypeValue, setUserTypeValue] = useState('')
    const handleSelect = (val) => {
        setSelect(val)

    }

    const [chk, setChk] = useState(false)

    const handleChk = () => {
        setChk(!chk)
    }

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [verifyEmail, setVerifyEmail] = useState(false)

    const handleVerifyEmail = () => {
        setVerifyEmail(!verifyEmail)
        setlemailerr(null)
        setlpassworderr(null)
    }

    const [forgetPaswd, setForgetPaswd] = useState(false)

    const handleForgetPaswd = () => {
        setForgetPaswd(!forgetPaswd)
    }

    const navigate = useNavigate()

    //login integration

    const [openpass, setOpenPass] = React.useState(false);
    const handleOpenTime = () => setOpenPass(true);
    const handleCloseTime = () => setOpenPass(false);

    const [showPasswordone, setShowPasswordone] = React.useState(false);
    const handleClickShowPasswordone = () => setShowPasswordone((show) => !show);

    const [showPasswordtwo, setShowPasswordtwo] = React.useState(false);
    const handleClickShowPasswordtwo = () => setShowPasswordtwo((show) => !show);

    const [showPasswordthree, setShowPasswordthree] = React.useState(false);
    const handleClickShowPasswordthree = () =>
        setShowPasswordthree((show) => !show);

    const [today, setDate] = useState(new Date());
    const [expanded, setExpanded] = useState(false);
    const [login, setLogin] = useState(false);
    const [recent, setResent] = useState(false);
    const [islogin2, setIslogin2] = useState(false);

    const femail = useRef(null);
    const remail = useRef(null);
    const lemail = useRef(null);
    const lpassword = useRef(null);
    const inpassref = useRef(null);
    const [open, setOpen] = useState(null);
    const [opens, setOpens] = useState(null);

    const [remailerr, setremailerr] = useState(null);
    const [emailerr, setemailerr] = useState(null);
    const [passworderr, setpassworderr] = useState(null);
    const [otp, setOtp] = useState('');
    const [otperr, setOtpErr] = useState('');
    const [verify, setVerify] = useState(false)

    const [lemailerr, setlemailerr] = useState(null);
    const [lpassworderr, setlpassworderr] = useState(null);
    const [femailerror, setfemailerror] = useState(null);
    const [islogin, setIslogin] = useState(false);
    const [active, setActive] = useState();
    const [is_send, setIssend] = useState(false);
    const [regMail, setRegmail] = useState('');
    const [otpLoad, setotpLoad] = useState()
    const [otpValue, setOtpValue] = useState(false)
    const [loginComponent, setLoginComponent] = useState(true)
    // console.log(otpValue ? otpValue : '', 'otpvalue');
    // console.log(loginCompoment, 'logincomponent');

    const [openModal, setOpenModal] = React.useState(false);
    const [user, setUser] = useState()
    const handleOpen = (user) => { setOpenModal(true); setUser(user) }
    const handleClose = () => setOpenModal(false);

    const current = new Date();
    const date = current.toLocaleString("en-us", {
        month: "long",
        year: "numeric",
        day: "numeric",
    });

    useEffect(() => {
        const timer = setInterval(() => {
            setDate(new Date());
        }, 60);
    }, []);

    const time = today.toLocaleTimeString({
        hour: "numeric",
        hour12: true,
        minute: "numeric",
    });

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };



    useEffect(() => {
        if (localStorage.getItem("nickname")) {
            setLogin(true);
        } else {
            setLogin(false);
        }
    }, []);

    const message = localStorage.getItem("URL");

    const handleresendOpen = () => {
        setOpen(false);
        setResent(true);
        setremailerr("");
    };
    const handleresendClose = () => {
        setResent(false);
        setOpenPass(false);
        setremailerr("");
    };

    const femailhandleChange = () => {
        const emailRegex =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const inputValue = femail.current.value;

        if (inputValue.trim() === "") {
            setfemailerror("");
        } else if (!emailRegex.test(inputValue)) {
            setfemailerror("*Invalid Email");
        } else {
            setfemailerror("");
        }
    };



    const [password, setPassword] = React.useState(false);
    const [lnameerr, setLnameerr] = useState(null);

    const handleClickPassword = () => setPassword((show) => !show);

    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };

    const handlePasswordChange = () => {
        const passwordregex = new RegExp(
            /(^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$)+/,
            "gm"
        );
        const inputValue = lpassword.current.value;

        if (inputValue.trim() === "") {
            setlpassworderr("");
        } else {
            setlpassworderr("");
        }
    };

    const handleChange1 = () => {
        setlpassworderr("");
    };

    const handleChange2 = () => {
        setlpassworderr("");
        setlemailerr("");
    };
    const handleCloses = () => {
        setpassworderr("");
        setemailerr("");
        setOpens(false);
    };

    const resendEmail = async () => {
        try {
            const emailRegex =
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!remail.current.value) {
                setremailerr("*Email is Required");
            } else if (!emailRegex.test(remail.current.value)) {
                setremailerr("*Invalid Email");
            } else {
                // console.log("Login")
                const mailData = {
                    email: remail.current.value,
                };
                window.localStorage.removeItem("iphephile");
                setIslogin(true);

                const { data } = await Axios.post("/re_send", mailData)
                    .then(async (res) => {
                        // console.log(res, "res")
                        if (res?.data?.success) {
                            localStorage.setItem('regMail', remail.current.value)
                            toast.success(
                                `Email send to you mail,Please Verify Your ${remail.current.value}`,
                                {
                                    duration: 1000,
                                }
                            );
                            setOtpValue(true)
                            setLoginComponent(false)
                            setremailerr("");
                            setIslogin(false);
                        }
                        else {
                            setIslogin(false);
                            // console.log(res,"daa")
                            if (res?.data.message === "User Does Not Exist") {
                                toast.error(res?.data?.message);
                                setTimeout(() => {
                                    setOtpValue(false)
                                    setVerifyEmail(false)
                                    setLoginComponent(true)
                                }, 1000);
                            } else if (res?.data.message === "Email already Verified") {
                                toast.error(res?.data?.message);
                                setTimeout(() => {
                                    setOtpValue(false)
                                    setVerifyEmail(false)
                                    setLoginComponent(true)
                                }, 1000);
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        // setOtpValue(false)
                        // setLoginComponent(true)
                        if (
                            err?.response?.data?.message === "User Does Not Exist" ||
                            err?.response?.data?.message === "BLOCKED_USER" ||
                            err?.response?.data?.message === "Please Verify Email" ||
                            err?.response?.data?.message === "USER DOES NOT EXIST"
                        ) {
                            toast.error(err?.response?.data?.message);
                            setTimeout(() => {
                                setOtpValue(false)
                                setVerifyEmail(false)
                                setLoginComponent(true)
                            }, 1000);
                        }
                        setIslogin(false);
                    });
            }
        } catch (error) {
            // setLoginComponent(true)
            // setOtpValue(false)
            setIslogin(false);

            //   toast.error("Something Went Wrong")
        }
    };

    const loginTo = async () => {
        try {
            const emailRegex =
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!lemail.current.value) {
                setlemailerr("*Email is Required");
            } else if (!emailRegex.test(lemail.current.value)) {
                setlemailerr("*Invalid Email");
            } else if (!lpassword.current.value) {
                setlpassworderr("*Password is required");
            } else {
                setIslogin(true);
                const loginData = {
                    email: lemail.current.value,
                    password: lpassword.current.value,
                };
                window.localStorage.removeItem("iphephile");

                await Axios.post("/login", loginData)
                    .then(async (res) => {
                        // console.log(res,"res")
                        if (res?.data?.success) {
                            window.localStorage.setItem(
                                "iphephile",
                                res?.data?.result?.token
                            );
                            window.localStorage.setItem(
                                "usrId",
                                res?.data?.result?.user?._id
                            );
                            window.localStorage.setItem(
                                "role",
                                res?.data?.result?.user?.role
                            );
                            window.localStorage.setItem("isPauses", false);
                            window.localStorage.setItem("currents", 0);
                            await Axios.post('/updateProfile', { login_time: new Date().getTime() }, {
                                headers: {
                                    Authorization: res?.data?.result?.token
                                }
                            })

                            toast.success("LoggedIn Successfully");

                            // if (message !== null) {
                            //     navigate(`${message}`);
                            //     localStorage.removeItem("URL");
                            // } else {
                            setTimeout(() => {
                                // navigate("/playernew");
                                navigate("/");
                                setIslogin(false);
                            }, 3000);
                            // }
                        } else {
                            if (
                                res?.data?.message === "Please Enter a Valid Email Address" ||
                                res?.data?.message === "BLOCKED_USER" ||
                                res?.data?.message === "Please Verify Email" ||
                                res?.data?.message === "USER DOES NOT EXIST"
                            ) {
                                setlemailerr(res?.data?.message);
                            }
                            if (
                                res?.data?.message === "Incorrect password" ||
                                res?.data?.message === "PASSWORD TOO SHORT MIN 5"
                            ) {
                                setlpassworderr(res?.data?.message);
                            } else {
                                setlemailerr(res?.data?.message);
                            }
                            setIslogin(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        if (
                            err?.response?.data?.message ===
                            "Please Enter a Valid Email Address" ||
                            err?.response?.data?.message === "BLOCKED_USER" ||
                            err?.response?.data?.message === "Please Verify Email" ||
                            err?.response?.data?.message === "USER DOES NOT EXIST"
                        ) {
                            setlemailerr(err?.response?.data?.message);
                        }
                        if (
                            err?.response?.data?.message === "Incorrect password" ||
                            err?.response?.data?.message === "PASSWORD TOO SHORT MIN 5"
                        ) {
                            setlpassworderr(err?.response?.data?.message);
                        } else {
                            setlemailerr(err?.response?.data?.message);
                        }
                        setIslogin(false);
                    });
            }
        } catch (error) {
            if (
                error?.response?.data?.message === "Invalid Email" ||
                error?.response?.data?.message === "Please Verify Email" ||
                error?.response?.data?.message === "USER DOES NOT EXIST"
            ) {
                setemailerr(error?.response?.data?.message);
            }
            if (
                error?.response?.data?.message === "Incorrect password" ||
                error?.response?.data?.message === "PASSWORD TOO SHORT MIN 5"
            ) {
                setpassworderr(error?.response?.data?.message);
            } else {
                setemailerr(error?.response?.data?.message);
            }
            setIslogin(false);
        }
        localStorage.setItem("NewUser", "false")
    };



    const signinwithGoogle = async (e) => {
        try {

            const provider = await new GoogleAuthProvider();
            return signInWithPopup(auth, provider)
                .then((res) => {
                    onAuthStateChanged(auth, async (user) => {
                        if (user) {
                            const registerdata = {
                                name: user.displayName,
                                firstname: user.displayName,
                                lastname: user.displayName,
                                email: user.email,
                                // signup_type: "google",
                                signin_type: '1',
                                typeUser: e
                            };
                            setIslogin2(true);

                            await Axios.post("/register", registerdata)
                                .then((res) => {
                                    if (res.data.success) {
                                        toast.success(res.data.message);
                                        window.localStorage.setItem(
                                            "iphephile",
                                            res?.data?.result?.token
                                        );
                                        window.localStorage.setItem(
                                            "usrId",
                                            res?.data?.result?.user?._id
                                        );
                                        window.localStorage.setItem(
                                            "role",
                                            res?.data?.result?.user?.role
                                        );
                                        window.localStorage.setItem("isPauses", false);
                                        window.localStorage.setItem("currents", 0);

                                        setTimeout(() => {
                                            setIslogin2(false);
                                            navigate("/");
                                        }, 2000);

                                    } else {
                                        // if (res.data.message == "User Does Not Exist") {
                                        //     toast.error("Username does not exist,Please Create a new Account");
                                        //     setTimeout(() => {
                                        //         navigate("/register");
                                        //     }, 2000);
                                        // }
                                        // else {
                                        if (res?.data?.message == 'User Type Missing') {
                                            navigate('/registernew', { state: { user: registerdata, type: 'google' } })
                                        }
                                        else {
                                            toast.error(res.data.message);
                                        }
                                        // }
                                        setTimeout(() => {
                                            setIslogin2(false);
                                        }, 2000);
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);


                                    if (error?.response?.data?.message == "User Does Not Exist") {
                                        toast.error("Username does not exist,Please Create a new Account");
                                        setTimeout(() => {
                                            navigate("/register");
                                        }, 2000);
                                    }
                                    else {

                                        toast.error(error?.response?.data?.message);

                                    }

                                    setTimeout(() => {
                                        setIslogin2(false);
                                    }, 2000);
                                    // toast.error("Error while Register to dreamster")
                                });
                        } else {
                            console.log("user");
                        }
                    });
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message);
                    setTimeout(() => {
                        setIslogin2(false);
                    }, 1000);
                });

        } catch (error) {
            toast.error(error?.response?.data?.message);
            setTimeout(() => {
                setIslogin2(false);
            }, 1000);
        }
    };





    const forgotPassSendEmail = async () => {
        try {
            const re = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
            if (femail.current.value === "") {
                setfemailerror("Please Enter your Email Address");
            } else if (!re.test(femail.current.value)) {
                setfemailerror("Please Enter Valid mail");
            } else {
                setIssend(true);
                const { data } = await Axios.post("/forgot", {
                    email: femail.current.value,
                });
                // console.log(data)

                if (data?.success) {
                    setfemailerror("");
                    toast.success(
                        `Please Check your Mail ${femail.current.value} to Reset your Password`,
                        {
                            duration: 1000, // Set the duration for which the toast is visible
                        }
                    );
                    // console.log(data)
                    femail.current.value = null;
                    setIssend(true);
                    setTimeout(() => {
                        // navigate(`${consts.route}/`)

                        navigate("/");
                        setIssend(false);
                    }, 2000);
                } else {
                    setActive(false);
                    setIssend(false);
                    femail.current.value = null;
                    if (data?.message === "User Not Found") {
                        setfemailerror("User Not Found");
                    } else if (data?.message === "Admin Blocked You") {
                        setfemailerror("Admin Blocked You");
                    } else {
                        toast.error("Somthing Went Wrong");
                    }
                }
            }
        } catch (error) {
            setActive(false);
            setIssend(false);

            console.log(error, "err");
        }
    };

    const handleNewVerify = async () => {
        try {
            const payload = {
                otpcode: otp,
                email: localStorage.getItem("regMail")
            };
            console.log(payload, 'payloaf');
            setotpLoad(true)
            const { data } = await Axios.post("/verify", payload);

            if (data?.success) {
                setOtp(false)
                setVerify(false)
                setLoginComponent(true)
                toast.success(data?.message);
                setTimeout(() => {
                    toast.success('Please Login');
                }, 2500)
                setTimeout(() => {
                    navigate("/login");
                }, 1500);
            } else {
                setotpLoad(false)
                // toast.error(data?.message);
                setOtpErr(data?.message);
            }
        } catch (error) {
            setotpLoad(false)
            setOtpErr(error?.response?.data?.message);
        }
    }

    useEffect(() => {
        if (localStorage.getItem('iphephile') != null) {
            navigate('/')
        }
    }, [])


    return (
        <>
            <ToastContainer />
            <div className='loginNew'>
                <Grid container style={{ justifyContent: 'center' }}>
                    <Grid xs={12} sm={12} md={12} lg={10} xl={7} className={`${!mdScreen && 'grd-bcgregis'} grd2`}>
                        <Grid container style={{ justifyContent: 'center', height: !mdScreen && '100%', alignItems: !mdScreen && 'center', backdropFilter: !mdScreen && 'blur(10px)' }}>
                            <Grid xs={12} sm={9} md={7} lg={6} xl={6}>

                                {verifyEmail && !otpValue ?

                                    <div className="maxdiv">
                                        <div className={!mdScreen ? "dmlogo text-center" : "dmlogo"}>
                                            <img src={dmlogo} alt="dmlogo" />
                                        </div>
                                        <div className="verifyemail-div">
                                            <div style={{ width: "100%" }}>
                                                <div className="mg-top-10 welcm-desc display-1 cursor" onClick={handleVerifyEmail}>

                                                    <ArrowBackIcon />

                                                    <div className="">Back</div>

                                                </div>
                                                <div className={!mdScreen ? "margin-top text-center" : "margin-top"}>
                                                    <div className="welcm" >
                                                        Resend Verification Mail
                                                    </div>

                                                </div>
                                                <div className="margin-top" style={{ width: '80%' }}>
                                                    <div className="fName">
                                                        Email Address*
                                                    </div>
                                                    <div className={!mdScreen && "text-center"}>
                                                        <TextField
                                                            className='logenew-inp'
                                                            id="outlined-basic"
                                                            placeholder='Enter your email address'
                                                            variant="outlined"
                                                            inputRef={remail}
                                                            onChange={() => {
                                                                setremailerr(null);
                                                            }}
                                                        />
                                                    </div>
                                                    {remailerr && <div className="errorDiv" style={{ textAlign: "left", marginTop: "5px" }}>
                                                        {remailerr}
                                                    </div>}
                                                </div>

                                                <div className="margin-top ">
                                                    <div className="margin-top regis" >
                                                        {islogin === false ? (
                                                            <Button onClick={() => { resendEmail() }}>Resend Mail</Button>
                                                        ) : (
                                                            <Button> Processing ...</Button>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                    : forgetPaswd ?

                                        <div className="maxdiv">
                                            <div className={!mdScreen ? "dmlogo text-center" : "dmlogo"}>
                                                <img src={dmlogo} alt="dmlogo" />
                                            </div>
                                            <div className="verifyemail-div">
                                                <div style={{ width: "100%" }}>

                                                    <div className={!mdScreen ? "margin-top text-center" : "margin-top"}>
                                                        <div className="welcm" >
                                                            Forgot password?
                                                        </div>

                                                    </div>
                                                    <div className="margin-top" style={{ width: '80%' }}>
                                                        <div className="fName">
                                                            Email Address*
                                                        </div>
                                                        <div className={!mdScreen && "text-center"}>
                                                            <TextField
                                                                className='logenew-inp'
                                                                id="outlined-basic"
                                                                placeholder='Enter your email address'
                                                                variant="outlined"
                                                                inputRef={femail}
                                                                onChange={() => {
                                                                    femailhandleChange();
                                                                }}
                                                            />
                                                        </div>
                                                        {femailerror && <div className="errorDiv" style={{ textAlign: "left", marginTop: "5px" }}>
                                                            {femailerror}
                                                        </div>}
                                                    </div>

                                                    <div className="margin-top ">
                                                        <div className="margin-top regis" >
                                                            {is_send === false ? (
                                                                <Button onClick={() => { forgotPassSendEmail() }}>Send Mail</Button>
                                                            ) : (
                                                                <Button>Processing ...</Button>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="alrdy margin-top">
                                                        Back to Login?
                                                        <span style={{ marginLeft: '5px' }} className='cursor' onClick={handleForgetPaswd}>Login</span>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        :
                                        loginComponent ?
                                            <div className="maxdiv">
                                                <div className={!mdScreen ? "dmlogo text-center" : "dmlogo"}>
                                                    <img src={dmlogo} alt="dmlogo" />
                                                </div>
                                                <div className={!mdScreen ? "margin-top text-center" : "margin-top"}>
                                                    <div className="welcm">
                                                        Welcome back!  👋
                                                    </div>
                                                    <div className="mg-top-10 welcm-desc">
                                                        Login to access all your data
                                                    </div>
                                                </div>
                                                <div className="margin-top mon-mar" style={{ width: '80%' }}>
                                                    <div className="fName">
                                                        Email Address*
                                                    </div>
                                                    <div className={!mdScreen && "text-center"}>
                                                        <TextField
                                                            className='logenew-inp'
                                                            id="outlined-basic"
                                                            placeholder='Enter your email address'
                                                            variant="outlined"
                                                            inputRef={lemail}
                                                            onChange={() => {
                                                                setlemailerr(null);
                                                            }}
                                                        />
                                                    </div>
                                                    {lemailerr && <div className="errorDiv" style={{ textAlign: "left", marginTop: "5px" }}>
                                                        {lemailerr}
                                                    </div>}
                                                </div>
                                                <div className="margin-top mon-mar" style={{ width: '80%' }}>
                                                    <div className="fName">
                                                        Password
                                                    </div>
                                                    <div className={!mdScreen && "text-center"}>
                                                        <FormControl variant="outlined" className='logenew-inp'>
                                                            {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                                                            <OutlinedInput
                                                                id="outlined-adornment-password"
                                                                type={showPassword ? 'text' : 'password'}
                                                                inputRef={lpassword}
                                                                onChange={() => {
                                                                    handlePasswordChange();
                                                                }}
                                                                placeholder='Password'
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            onMouseDown={handleMouseDownPassword}
                                                                            edge="end"
                                                                        >
                                                                            {showPassword ? <Visibility sx={{ fill: '#49475A' }} /> : <VisibilityOff sx={{ fill: '#49475A' }} />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                            // label="Password"
                                                            />
                                                        </FormControl>
                                                    </div>
                                                    {lpassworderr && <div className="errorDiv" style={{ textAlign: "left", marginTop: "5px" }}>
                                                        {lpassworderr}
                                                    </div>}
                                                    <div className="fName text-right cursor" onClick={handleForgetPaswd}>
                                                        Forgot Password?
                                                    </div>
                                                </div>
                                                <div className="margin-top mon-mar">


                                                    <div className="margin-top regis" >
                                                        {islogin === false ? (
                                                            <Button onClick={() => { loginTo() }}>Log In</Button>
                                                        ) : (
                                                            <Button>Processing ...</Button>
                                                        )}
                                                    </div>

                                                    <div className="margin-top">
                                                        <div className="display-3">
                                                            <svg width="172" height="2" viewBox="0 0 172 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <line x1="0.84375" y1="0.952148" x2="171.547" y2="0.952163" stroke="#686677" stroke-width="0.742188" />
                                                            </svg>
                                                            <div className="ro">
                                                                Continue with
                                                            </div>
                                                            <svg width="172" height="2" viewBox="0 0 172 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <line x1="0.84375" y1="0.952148" x2="171.547" y2="0.952163" stroke="#686677" stroke-width="0.742188" />
                                                            </svg>

                                                        </div>
                                                    </div>

                                                    <div className="margin-top regis-g" >
                                                        {islogin2 === false ?
                                                            <Button onClick={() => { signinwithGoogle() }}>
                                                                <div className="display-1">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                                                        <path d="M16.1799 8.21732H15.582V8.18652H8.90234V11.1553H13.0968C12.4849 12.8835 10.8406 14.124 8.90234 14.124C6.44311 14.124 4.44922 12.1301 4.44922 9.6709C4.44922 7.21166 6.44311 5.21777 8.90234 5.21777C10.0375 5.21777 11.0703 5.64602 11.8566 6.34553L13.9559 4.24625C12.6304 3.01088 10.8573 2.24902 8.90234 2.24902C4.80361 2.24902 1.48047 5.57217 1.48047 9.6709C1.48047 13.7696 4.80361 17.0928 8.90234 17.0928C13.0011 17.0928 16.3242 13.7696 16.3242 9.6709C16.3242 9.17326 16.273 8.6875 16.1799 8.21732Z" fill="#FFC107" />
                                                                        <path d="M2.33643 6.21639L4.77488 8.00469C5.43469 6.37113 7.03262 5.21777 8.90256 5.21777C10.0377 5.21777 11.0705 5.64602 11.8568 6.34553L13.9561 4.24625C12.6306 3.01088 10.8575 2.24902 8.90256 2.24902C6.05182 2.24902 3.57959 3.85846 2.33643 6.21639Z" fill="#FF3D00" />
                                                                        <path d="M8.90225 17.0932C10.8193 17.0932 12.5612 16.3596 13.8782 15.1665L11.5812 13.2227C10.811 13.8085 9.86986 14.1253 8.90225 14.1245C6.97182 14.1245 5.3327 12.8936 4.7152 11.1758L2.29492 13.0405C3.52324 15.4441 6.01773 17.0932 8.90225 17.0932Z" fill="#4CAF50" />
                                                                        <path d="M16.1799 8.21732H15.582V8.18652H8.90234V11.1553H13.0968C12.8041 11.9778 12.2768 12.6965 11.5802 13.2226L11.5813 13.2219L13.8783 15.1657C13.7158 15.3134 16.3242 13.3818 16.3242 9.6709C16.3242 9.17326 16.273 8.6875 16.1799 8.21732Z" fill="#1976D2" />
                                                                    </svg>
                                                                    <div className="">
                                                                        Login with Google
                                                                    </div>
                                                                </div>
                                                            </Button>
                                                            : (
                                                                <Button>Processing ...</Button>
                                                            )}

                                                    </div>

                                                    {/* <div className="margin-top regis-g">
                                                    <Button>
                                                        <div className="display-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
                                                                <g clip-path="url(#clip0_4140_11536)">
                                                                    <path d="M20.9492 10.8906C20.9492 5.15208 16.2971 0.5 10.5586 0.5C4.82005 0.5 0.167969 5.15208 0.167969 10.8906C0.167969 16.0768 3.96769 20.3756 8.93506 21.155V13.8942H6.29681V10.8906H8.93506V8.60144C8.93506 5.99729 10.4863 4.55884 12.8598 4.55884C13.9966 4.55884 15.1857 4.76178 15.1857 4.76178V7.31885H13.8755C12.5847 7.31885 12.1821 8.11982 12.1821 8.94157V10.8906H15.0639L14.6032 13.8942H12.1821V21.155C17.1495 20.3756 20.9492 16.0769 20.9492 10.8906Z" fill="#1877F2" />
                                                                    <path d="M14.6033 13.8939L15.064 10.8904H12.1822V8.94133C12.1822 8.11949 12.5847 7.3186 13.8755 7.3186H15.1857V4.76154C15.1857 4.76154 13.9967 4.55859 12.8598 4.55859C10.4864 4.55859 8.93512 5.99705 8.93512 8.6012V10.8904H6.29688V13.8939H8.93512V21.1548C9.4722 21.2389 10.015 21.2811 10.5587 21.281C11.1023 21.2812 11.6451 21.239 12.1822 21.1548V13.8939H14.6033Z" fill="white" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_4140_11536">
                                                                        <rect width="20.7812" height="20.7812" fill="white" transform="translate(0.167969 0.5)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            <div className="">
                                                                Login with Facebook
                                                            </div>
                                                        </div>
                                                    </Button>
                                                </div> */}
                                                    <div className="alrdy margin-top">
                                                        Don’t have an account?<span style={{ marginLeft: '5px' }} className='cursor' onClick={(() => { navigate('/register') })}>Register</span>
                                                    </div>
                                                    <div className="alrdy margin-top">
                                                        Don't have verify Email?
                                                        <span style={{ marginLeft: '5px' }} className='cursor' onClick={() => { handleVerifyEmail() }}>Resend Verification Mail</span>
                                                    </div>

                                                </div>
                                            </div>
                                            :
                                            otpValue ?
                                                <>

                                                    <div className="maxdiv">
                                                        <div className={!mdScreen ? "dmlogo text-center" : "dmlogo"}>
                                                            <img src={dmlogo} alt="dmlogo" />
                                                        </div>
                                                        <div className="verifyemail-div">
                                                            <div style={{ width: "100%" }}>

                                                                <div className={!mdScreen ? "margin-top text-center" : "margin-top"}>
                                                                    <div className="welcm" >
                                                                        Resend Verification Mail?
                                                                    </div>

                                                                </div>
                                                                <div className="margin-top" style={{ width: '80%' }}>
                                                                    <div className="fName">
                                                                        Enter Otp
                                                                    </div>
                                                                    <TextField
                                                                        className='logenew-inp'
                                                                        id="outlined-basic"
                                                                        placeholder='Enter Otp'
                                                                        value={otp}
                                                                        variant="outlined"
                                                                        onChange={(e) => { setOtpErr(''); setOtp(e.target.value) }}
                                                                    />
                                                                </div>
                                                                {otperr && <div style={{ textAlign: 'center', color: 'red' }}>{otperr}</div>}

                                                                <div className="margin-top ">
                                                                    <div className="margin-top regis" >
                                                                        {
                                                                            otpLoad ?
                                                                                <Button>Processing...</Button>
                                                                                :
                                                                                <Button onClick={() => { handleNewVerify() }}>Submit</Button>

                                                                        }
                                                                    </div>
                                                                    <div className="alrdy margin-top">
                                                                        Back to  <span className='cursor' onClick={() => { setVerifyEmail(false); setOtpValue(false); setLoginComponent(true) }}>Login</span>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </>
                                                :
                                                <></>
                                }

                            </Grid>
                            {mdScreen && <Grid xs={12} sm={3} md={5} lg={6} xl={6}>

                                <div className="lognew">
                                    <img src={regN} alt="lognew" style={{ width: !lgScreen && '100%' }} />
                                </div>

                            </Grid>}


                        </Grid>
                    </Grid>
                </Grid>
            </div >
        </>
    )
}

export default LoginNew
